/* body {
  
} */

body {
  background-color: #a397d8;
  color: #fff;
  font-family: Quicksand, sans-serif;
}

.App {
  overflow: hidden;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.modal.hidden {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.logo {
  max-width: 200px;
  position: absolute;
  top: 0;
}

.btn-icon {
  display: flex;
  width: 50px;
  height: 50px;
}

.btn-icon img {
  width: 50px;
  height: 50px;
}

img {
  width: 100%;
}

.video {
  bottom: 0;
  height: 100vh;
  left: 0;
  object-fit: cover;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

.btn {
  align-items: center;
  background-color: #ffba4a;
  border: 1px solid #1e160e;
  border-radius: 15px;
  color: #1e160e;
  display: flex;
  font-size: 30px;
  font-weight: 700;
  gap: 40px;
  height: 50px;
  justify-content: space-between;
  padding: 10px 15px;
  transition: all 0.3s linear;
  width: 280px;
}

.btn-group {
  flex-direction: column;
  gap: 140px;
  left: 50%;
  max-width: 1050px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}

.btn-group-row {
  gap: 50px;
}

.btn-wrapper-icon {
  position: absolute;
  top: -40px;
}

.btn-group,
.btn-group-row {
  display: flex;
  justify-content: space-between;
}

.btn-wrapper {
  background-color: #b97643;
  border: 1px solid #1e160e;
  border-radius: 15px;
  cursor: pointer;
  padding: 5px;
  position: relative;
}

.btn {
  align-items: center;
  background-color: #ffba4a;
  border: 1px solid #1e160e;
  border-radius: 15px;
  color: #1e160e;
  display: flex;
  font-size: 30px;
  font-weight: 700;
  gap: 40px;
  height: 50px;
  justify-content: space-between;
  padding: 30px 15px;
  transition: all 0.3s linear;
  width: 350px;
}

.btn-social,
.footer {
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.btn-social {
  bottom: 70px;
  display: flex;
  gap: 30px;
}

.modal {
  background-color: #a397d8;
  border-radius: 10px;
  left: 50%;
  padding: 100px 50px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 999;
  text-align: center;
}

.modal h4 {
  font-size: 30px;
  font-weight: 700;
}

.modal-btn-group {
  display: flex;
  font-size: 22px;
  gap: 50px;
  justify-content: center;
  margin-top: 50px;
}

.modal-btn-group span {
  background-color: #ffba4a;
  border: 1px solid #1e160e;
  border-radius: 10px;
  cursor: pointer;
  display: inline-flex;
  padding: 15px 25px;
  transition: all 0.3s linear;
}

@media screen and (max-width: 900px) {
  .btn-group {
    gap: 50px;
    /* flex-direction: column; */
  }
  .btn-group-row {
    align-items: center;
    flex-direction: column;
  }

  .modal {
    padding: 50px 20px;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    border: 1px solid #1e160e;
    width: 95%;
  }
  .modal h4 {
    font-size: 25px;
  }
  .modal-btn-group {
    font-size: 18px;
    gap: 20px;
  }

  .modal-btn-group {
    font-size: 18px;
    gap: 20px;
  }
}
